<template>
  <div class="page-404">
    <a-result title="404 NotFound" sub-title="The page you are looking for either doesn't exist or doesn't exist.">
      <template #icon>
        <img :src="NotFound" />
      </template>
      <template #extra>
        <a-button type="primary" @click="navigateToHome">Back homepage</a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
import NotFound from '@/assets/svg/404.svg'
export default {
  name: 'NotFound',
  data() {
    return {
      NotFound
    }
  },
  methods: {
    navigateToHome() {
      this.$router.replace({
        path: '/'
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>
